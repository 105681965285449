import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import {
  ActivatedRouterStateSnapshot,
  CurrencyService,
  LanguageService,
  ProductSearchPage,
  ProductSearchService,
  RouterState,
  RoutingService,
  SearchConfig,
} from '@spartacus/core';
import { ProductListComponentService, SearchCriteria } from '@spartacus/storefront';
import { combineLatest, Observable } from 'rxjs';
import { debounceTime, distinctUntilKeyChanged, filter, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class BossProductListComponentService extends ProductListComponentService {
  defaultPageSize = 60;

  constructor(
    productSearchService: ProductSearchService,
    routing: RoutingService,
    activatedRoute: ActivatedRoute,
    currencyService: CurrencyService,
    languageService: LanguageService,
    router: Router,
    private pageSizeStore: Store<any>, // eslint-disable-line
  ) {
    super(productSearchService, routing, activatedRoute, currencyService, languageService, router);
  }

  searchByRouting$: Observable<ActivatedRouterStateSnapshot> = combineLatest([
    this.routing.getRouterState().pipe(
      map((routerState: RouterState) => routerState?.state),
      distinctUntilKeyChanged('url'),
    ),
    this.pageSizeStore.pipe(select('pageSize')),
  ]).pipe(
    debounceTime(0),
    // eslint-disable-next-line
    map(([snapshot, { pageSize }]: [ActivatedRouterStateSnapshot, any]) => {
      const criteria = this.getCriteriaFromRoute(snapshot.params, {
        ...snapshot.queryParams,
        pageSize: pageSize?.pageSize,
      });

      this.search(criteria);

      return snapshot;
    }),
  );

  solrModel$: Observable<ProductSearchPage> = combineLatest([
    this.routing.getRouterState().pipe(
      map((routerState: RouterState) => routerState?.state),
      distinctUntilKeyChanged('url'),
    ),
    this.productSearchService.getResults().pipe(filter((results: ProductSearchPage) => !!results.products?.length)),
  ]).pipe(map(([, results]: [ActivatedRouterStateSnapshot, ProductSearchPage]) => results));

  search(criteria: SearchCriteria, source?: string): void {
    const currentPage = criteria.currentPage ?? 1;
    const pageSize = criteria.pageSize;
    const sort = criteria.sortCode;

    const searchConfig: SearchConfig = {
      ...(+currentPage - 1 && { currentPage: +currentPage - 1 }),
      ...(pageSize && { pageSize }),
      ...(sort && { sort }),
    };

    this.productSearchService.search(criteria.query, searchConfig);
  }
}
